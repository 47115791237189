.custom-button {
    &.active-dark {
        @include border-radius(2px);
        border: 1px solid $primary-500;
        background: $background-03 !important;
        border-radius: 1rem;
        &:hover {
            @include no-box-shadow;
            background: $background-01 !important;
        }
    }
    &.active-light {
        @include border-radius(2px);
        border: 1px solid $primary-500;
        background: $background-03-light !important;
        border-radius: 1rem;
        &:hover {
            @include no-box-shadow;
            background: $background-01-light !important;
        }
    }
    &.button {
        @include border-radius(2px);
        border: 1px solid $primary-500;
        &.form-control {
            border-radius: 2px !important;
        }

        &.auth-button {
            @extend .font-base;
            font-weight: $font-weight-bold;
        }
        &.merged-button {
            border-radius: 2px !important;
            padding: 3px;
        }
    }
    &.button-link {
        @extend .btn-link;
        text-decoration: none;
        &.underline {
            text-decoration: underline;
        }
    }

    &.button-cross {
        @include border-radius(2px);
        .border-right {
            border-right: 1px solid $br-01 !important;
        }
    }
    &.command-button {
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 12px !important;
    }
    &.command-button-dark {
        @include no-box-shadow;
        border: none !important;
        background: $background-02 !important;
        opacity: 70%;
    }
    &.command-button-clicked {
        @include no-box-shadow;
        @include border-radius(16px);
        border: 2px solid var(--button-bt-01-day, $br-03-light) !important;
    }
    &.command-button-light {
        @include no-box-shadow;
        border: none !important;
        background: $background-02-light !important;
        opacity: 70%;
    }
    &.command-button-light-clicked {
        @include no-box-shadow;
        @include border-radius(16px);
        border: 2px solid var(--button-bt-01-day, $br-03-light) !important;
    }
    &.date-button {
        @extend .command-button;
        color: $white !important;
        &:hover {
            text-decoration: none !important;
        }
    }
    &.date-button-dark {
        @include no-box-shadow;
        border-radius: 0 !important;
    }
    &.date-button-light {
        @include no-box-shadow;
        border-radius: 0 !important;
        color: $black !important;
        text-decoration: none !important;
    }
    &.activebtnlight {
        @extend .date-button-light;
        color: $primary-button !important;
    }
    &.activebtndark {
        @extend .date-button-light;
        color: $primary-button !important;
    }
    & + .dot-above-button {
        top: -0.188rem;
        left: 50%;
        transform: translateX(-50%);
        width: 0.375rem;
        height: 0.375rem;
        background-color: $primary-button;
        border-radius: 50%;
    }
    // todo: brand button icon margin
    .btn-brand:not(:only-child) {
        .c-icon {
            margin-top: 0 !important;
        }
    }

    &.btn-search {
        background-color: $primary;
    }

    &.btn-pill {
        border: 1px solid $br-01;
        border-radius: 10px;
    }
}
