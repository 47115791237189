.custom-table {
    &.fix-height {
        min-height: 40vh;
        max-height: 56vh;
        overflow: scroll;
    }
    &.table {
        margin-bottom: 0px;
        thead tr th {
            font-weight: 400;
        }
    }
}

.custom-table-remote-commands {
    &.fix-height {
        min-height: 60vh;
        max-height: 70vh;
        overflow: scroll;
    }
    &.table {
        margin-bottom: 15px;
        thead tr th {
            font-weight: 400;
        }
    }
    tr.fixed-row {
        position: sticky;
        top: 0;
        z-index: 1;
    }
}
.custom-table-add-firmware-list {
    &.fix-height {
        min-height: 40vh;
        max-height: 72vh;
        overflow: scroll;
    }
    &.table {
        thead tr th {
            font-weight: 400;
        }
    }
}

.commands-table-without-scroll {
    border-collapse: separate;
    border-spacing: 0 15px;
    width: 100%;
}
.commands-table-with-scroll {
    border-collapse: separate;
    min-width: 1400px;
    border-spacing: 0 15px;
    width: 100%;
}
.commands-table-div {
    justify-content: end;
    display: flex;
}
.commands-table-th {
    align-self: flex-end;
}
.commands-table-text-align {
    text-align: center;
}
.commands-table-row {
    height: 3.125rem;
    font-size: 0.75rem;
    margin-bottom: 0.625rem;
    font-weight: $font-weight-light;
}
.add-firmware-list-table-row {
    @extend .commands-table-row;
    height: 2.375rem;
}

.custom-table-siterhs {
    width: 100%;
    border-collapse: collapse;
}

.custom-table-siterhs th,
.custom-table-siterhs td {
    padding: 0.25rem;
    text-align: left;
}
