@mixin toast-header-variant($color) {
    background: $color;
    border: none !important;
    padding-bottom: 0 !important;
}

.toast-toaster-success .toast-header {
    @include toast-header-variant($toaster-success);
}
.toast-toaster-danger .toast-header {
    @include toast-header-variant($toaster-danger);
}
.toast-toaster-warning .toast-header {
    @include toast-header-variant($toaster-warning);
}
.toast-toaster-info .toast-header {
    @include toast-header-variant($toaster-info);
}
.toast-body {
    padding: 0;
}
.toaster-progress-bar {
    height: 0.25rem;
    transition: width 0.05s linear;
}
.close {
    opacity: 1 !important;
}
.toaster-body-font {
    color: $t-02;
}
