.time-picker {
    .react-datepicker__time-container {
        width: auto;
    }

    .react-datepicker__time {
        overflow: hidden;
    }

    .react-datepicker__header--time,
    .react-datepicker__triangle {
        display: none !important;
        margin: 0 !important;
    }

    .react-datepicker--time-only,
    .react-datepicker__time-box,
    .react-datepicker__time-list {
        border-color: $bottom-bar !important;
    }
}

@mixin time-picker-theme($background-02, $background-01, $background-03, $t-01) {
    .react-datepicker__time-list-item {
        background-color: $background-02 !important;
        color: $t-01 !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;

        &:hover {
            background-color: $background-01 !important;
        }
    }

    .react-datepicker__time-list-item--selected {
        background-color: $background-01 !important;
    }

    .react-datepicker__time-list {
        &::-webkit-scrollbar {
            width: 0.375rem !important;
            border: none !important;
        }

        &::-webkit-scrollbar-track {
            background: $background-02;
        }
    }

    .react-datepicker__input-container input {
        @extend .font-base;
        background-color: $background-03 !important;
        color: $t-01 !important;
        border: 1px solid $bottom-bar !important;
        padding: 0.313rem !important;
        width: 8.125rem;

        &:focus {
            outline: none !important;
        }
    }
}

.time-picker-dark-theme {
    @include time-picker-theme($background-02, $background-01, $background-03, $t-01);
}

.time-picker-light-theme {
    @include time-picker-theme($background-02-light, $background-01-light, $background-03-light, $t-01-light);
}
