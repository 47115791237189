.custom-input {
    &.custom-input-box {
        @extend .font-base;
        background-color: transparent !important;
        width: 80%;
        padding: 20px;
        &.form-control {
            border-radius: 2px !important;
            background-color: transparent !important;
        }
        &.invalid-input {
            border: 1px solid $danger;
        }
        &.time-input {
            width: 39px !important;
            height: 28px !important;
            padding: 2px !important;
            text-align: center !important;
        }
    }

    &.invalid {
        color: $danger;
        width: 100%;
    }
    &.input-prepend {
        position: absolute;
        right: 10px;
        z-index: 99;
    }
    &.input-append {
        position: absolute;
        z-index: 99;
    }
    &.custom-search-input-box {
        @extend .font-base;
        padding-right: 90px;
        padding-left: 30px;
        background-color: transparent !important;
        height: 2.25rem;
        &.form-control {
            border-radius: 2px !important;
            background-color: transparent !important;
        }

        &.invalid-input {
            border: 1px solid $danger;
        }
        &.invalid {
            color: $danger;
            border: none;
            width: 100%;
        }
    }
    &.custom-search-site-input-box {
        @extend .custom-search-input-box;
        padding-right: 0;
        padding-left: 0;
    }

    &.icon-append {
        border: none;
        overflow: hidden;
        position: absolute;
        left: 8px;
        margin: 2px;
        margin-top: 2px;
        padding: 0px;
        z-index: 999;
    }
    &.icon-prepend {
        overflow: hidden;
        position: absolute;
        right: 0px;
        z-index: 999;
    }
}

.table-search-input {
    border-radius: 2px !important;
    .custom-input {
        &.custom-search-input-box {
            height: 35px;
        }
    }
}

.dark {
    input[type='datetime-local']::-webkit-calendar-picker-indicator {
        cursor: pointer;
    }
    input[type='date']::-webkit-calendar-picker-indicator {
        cursor: pointer;
    }
}
.light {
    input[type='datetime-local']::-webkit-calendar-picker-indicator {
        cursor: pointer;
    }
    input[type='date']::-webkit-calendar-picker-indicator {
        cursor: pointer;
    }
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: $white;
    --webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
    background: $white;
}

.dropdown-search-input {
    overflow: scroll;
    max-height: 50%;
}

.time-invalid {
    color: $danger;
    width: 15px;
}

input[type='datetime-local']::-webkit-calendar-picker-indicator {
    background: transparent;
    color: transparent;
    cursor: pointer;
    height: auto;
    position: absolute;
    width: auto;
    inset: 0;
}
.selected-values-placeholder-dark-theme::placeholder {
    color: $white;
}
.selected-values-placeholder-light-theme::placeholder {
    color: $black;
}
