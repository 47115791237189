.command-custom-btn {
    margin-bottom: 1rem;
    width: 2.313rem;
    height: 1.688rem;
    border: 0.063rem solid $br-03-light !important;
    border-radius: 0.125rem !important;
}
.command-icon-bg {
    color: $br-03-light;
}

.commandstable {
    box-sizing: border-box;
    width: 14.5rem;
    height: 6.875rem;
    left: 75rem;
    top: 4.063rem;
    background: $background-03;
    border: 0.063rem solid rgba(68, 142, 202, 0.4);
    border-radius: 0.25rem;
}
.node-card-div {
    height: 1.625rem !important;
}
.device-command-searchbar {
    width: 27.563rem !important;
}

.command-button-box {
    border: 2px dashed rgba(68, 142, 202, 0.4) !important;
    @include border-radius(2px);
    box-sizing: border-box;
}
.command-loading-panel-box {
    border: none !important;
    box-sizing: border-box;
    height: 16.563rem;
}

.device-breadcrumb-command-table {
    color: $gray-30;
}
.device-command-table-row {
    background-color: rgba(129, 123, 128, 0.4);
    font-weight: $font-weight-light;
    opacity: 50%;
}
.device-command-table-row td {
    background-color: transparent;
    font-weight: inherit;
}

.run-command-button-text {
    font-size: 0.75rem;
    font-weight: $font-weight-light;
    line-height: 14.4px;
}

.success-tick {
    background: $t-03-light;
    color: $black-700;
    border-radius: 50%;
    text-align: center;
}
.command_error {
    color: $danger;
}

.success-text {
    font-weight: $font-weight-bold;
    font-size: 1.25rem;
    text-align: center;
}
.command-text {
    font-weight: $font-weight-light;
    font-size: 1.063rem;
    text-align: center;
}

.error-text {
    @extend .success-text;
}
.sending_text {
    @extend .success-text;
}

.octagonWrap {
    width: 2.021rem;
    height: 2.021rem;
    position: relative;
    overflow: hidden;
}
.octagon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    transform: rotate(45deg);
    background: $background-01;
    text-transform: none !important;
}

.octagon:before {
    position: absolute;
    top: -0.188rem;
    right: -0.188rem;
    bottom: -0.188rem;
    left: -0.188rem;
    content: '';
    border: inherit;
}
.octagonWrap em {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(316deg);
    color: $danger;
}
.device-command-text {
    font-weight: $font-weight-light;
    line-height: 16.8px;
}
.button-bold {
    font-weight: $font-weight-bold !important;
}
.no-button {
    font-weight: $font-weight-bold !important;
}
.checkbox-leftalignment {
    padding-left: 0.625rem;
}
.row-checkbox-leftalignment {
    padding-left: 0.688rem;
}
.particular-checkbox {
    padding-left: 0.688rem;
}
.ManualOverrideSection {
    width: 38.438rem;
}
.ManualOverrideSliderSection {
    width: 23.75rem;
}

.range {
    position: relative;
}
.LabelBase {
    font-size: 0.625rem;
    font-weight: $font-weight-bold;
}

.LabelDefault {
    @extend .LabelBase;
    background: none;
}

.LabelGreen {
    color: #cfccff;
    @extend .LabelBase;
}
.LabelBlue {
    color: $background-01;
    @extend .LabelBase;
}

.LabelPurple {
    color: $medium;
    @extend .LabelBase;
}

.LabelRed {
    color: $high-occupancy;
    @extend .LabelBase;
}
.LabelGreenWhite {
    color: #cfccff;
    @extend .LabelBase;
}
.LabelBlueWhite {
    color: $occupied-light;
    @extend .LabelBase;
}

.LabelPurpleWhite {
    color: $medium;
    @extend .LabelBase;
}

.LabelRedWhite {
    color: $high-occupancy;
    @extend .LabelBase;
}

.customSlider {
    /* max width of your slider */
    max-width: 19.438rem;
}

.customSlider-track {
    /* Top value to align your track to the center of your thumb */
    top: 0.5rem;
    height: 0.5rem;
    border-radius: 0.375rem;
}

.customSlider-track.customSlider-track-0 {
    /* color of the track before the thumb */
    background: $br-02-light;
}

.customSlider-mark {
    cursor: pointer;
    top: 0.619rem;
    width: 0.25rem;
    height: 0.25rem;
    border-radius: 100%;
    background-color: $white;
}

.slider-mark-common {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    position: relative;
    top: 0.5rem;
}
.customSlider-mark.customSlider-mark-25 {
    @extend .slider-mark-common;
    border: 2px solid $background-01;
}
.customSlider-mark.customSlider-mark-25-light {
    @extend .slider-mark-common;
    border: 2px solid $occupied-light;
}

.customSlider-track-base {
    /* Common styles for both tracks */
    border-radius: 0.375rem 0.375rem 0.375rem 0.375rem;
}
.customSlider-track.customSlider-track-25 {
    /* color of the track before the thumb */
    background: #cfccff;
    @extend .customSlider-track-base;
    right: 12.5rem !important;
}
.customSlider-track.customSlider-track-50 {
    /* color of the track before the thumb */
    background: $medium;
    @extend .customSlider-track-base;
    right: 6rem !important;
}
.customSlider-track.customSlider-track-75 {
    /* color of the track before the thumb */
    background: $high-occupancy;
    border-radius: 0.375rem;
}

.slider-labels {
    padding-top: 1.75rem;
    padding-right: 1.188rem;
}
.vertical-line {
    width: 0.063rem; /* Set the width of the vertical line */
    height: 9.188rem; /* Set the height of the vertical line */
    margin-top: 0.625rem;
}
.remaining-Time {
    font-size: 1.813rem;
}
.time-mode-standby {
    color: $background-01;
}
.time-mode-low {
    color: #cfccff;
}
.time-mode-medium {
    color: $medium;
}
.time-mode-high {
    color: #0e084f;
}
.cross-icon {
    margin-right: 0.688rem;
}

.state {
    border-radius: 0.188rem;
    margin-top: 0.188rem;
}
.custom-cell {
    flex-direction: column;
}

.time-info {
    flex-direction: row;
    margin-top: 0.313rem; /* Adjust margin as needed */
}
.hours-minuete-input {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.time-dropdown {
    width: 3.5rem !important;
    height: 1.75rem !important;
}
.time-dropdown-left-alignment {
    padding-left: 0.25rem !important;
}
.timeRepetation-dropdown {
    width: 13.875rem !important;
    height: 1.75rem !important;
}
.timeRepetation-dropdown-left-alignment {
    padding-left: 0.375rem !important;
}
.vertical-line-planned-override {
    width: 0.063rem;
    height: auto;
    margin-top: 0.9rem;
    margin-bottom: 0.9rem;
}
@media (max-width: 990px) {
    .vertical-line-planned-override {
        height: 0.125rem;
        width: 100%;
    }
}
.track {
    width: 24.134rem;
    height: 0.5rem;
}

.track-data {
    height: 100%;
    border-radius: 0;
}
.default-track {
    @extend .track;
    border-radius: 0.25rem;
    background: $br-02-light;
}

.slider-dot-container {
    position: relative;
    height: 16px; /* Adjust the height as needed */
    background-color: #ccc; /* Background color of the slider track */
}

.slider-dots {
    position: absolute;
    width: 0.438rem; /* Adjust the dot width as needed */
    height: 0.438rem; /* Adjust the dot height as needed */
    background-color: $white; /* Dot color */
    border-radius: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
}

.border-curve {
    border-radius: 0.25rem;
}
.remember-me-text {
    font-size: $font-10;
    font-weight: $font-weight-light;
}
/* Absolute positioning */
.Tooltip-Tip {
    background: $primary-button;
    top: -410% !important;
    margin-left: -2.063rem;
}

/* CSS border triangles */
.Tooltip-Tip::before {
    content: ' ';
    border: solid transparent;
    position: absolute;
    margin-left: 1.438rem;
}

/* CSS border triangles */
.Tooltip-Tip.top::before {
    top: 100%;
    border-top-color: $primary-button;
    border-width: 0.313rem;
}
.vertical-line-Tooltip {
    width: 0.063rem; /* Set the width of the vertical line */
    height: 1.25rem;
    background: $background-02-light;
}
.vertical-line-self-clean {
    @extend .vertical-line;
    height: 12.125rem;
}
@media (max-width: 990px) {
    .vertical-line-self-clean {
        height: 0.125rem;
        width: 100%;
    }
}
.dropdown-height {
    height: 2.25rem;
}
.td-text-alignment {
    text-align: -webkit-center;
}
.device-list-breadcrumbs-icon {
    height: 1.875rem;
}
.custom-tooltip-site-options {
    z-index: 999 !important;
}
.cross_icon_alignment {
    top: 0;
    right: 0;
}
.schedule-info-header-section {
    border: 0.063rem solid;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}
.cross-button {
    color: $primary-button !important;
    border: 0.063rem solid $primary-button !important;
}
.schedule-time-label {
    position: absolute;
    top: 0;
    z-index: 1;
}
.schedule-bar {
    position: absolute;
    bottom: 0;
}
.schedule-container {
    width: 99%;
    height: 33px;
    position: relative;
}
.scheduler-legends {
    width: 1.875rem;
    height: 1.875rem;
}

.occupancy-box {
    height: 30px;
    width: 30px;
}

.non-occupied-dark {
    @extend .occupancy-box;
    background: $background-01;
}
.non-occupied-light {
    @extend .occupancy-box;
    background: $occupied-light;
}

.low-occupancy-dark {
    @extend .occupancy-box;
    background: linear-gradient(to bottom, $background-01 25px, #cfccff 25px 30px);
}
.low-occupancy-light {
    @extend .occupancy-box;
    background: linear-gradient(to bottom, $occupied-light 25px, #cfccff 25px 30px);
}

.moderate-occupancy-dark {
    @extend .occupancy-box;
    background: linear-gradient(to bottom, $background-01 15px, $medium 15px 30px);
}
.moderate-occupancy-light {
    @extend .occupancy-box;
    background: linear-gradient(to bottom, $occupied-light 15px, $medium 15px 30px);
}

.high-occupancy {
    @extend .occupancy-box;
    background: $high-occupancy;
}
.current-time-pin {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
}
.current-time-indicator {
    width: 0.063rem;
    height: 2.563rem;
    top: 0.25rem;
    background-color: $time-indicator;
    position: relative;
}
.current-time-pin-dot {
    width: 0.625rem;
    height: 0.625rem;
    background-color: $time-indicator;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}
.day-border {
    border: 0.063rem solid $time-indicator;
    border-radius: 16px;
}
.time-indicator-dash-border {
    bottom: '0';
    border-left: 0.063rem dashed;
    height: 26.125rem;
    opacity: 0.4;
    width: 4.16%;
}
.time-indicator-dash-last-border {
    @extend .time-indicator-dash-border;
    border-right: 0.063rem dashed;
}
.add_schedule_day_button {
    width: 2.625rem;
}
.add_schedule_form {
    width: 24.125rem;
    height: 30.375rem;
    margin-top: 50%;
}
.time-mode-standby-dark {
    background: $background-01;
    color: $white;
}
.time-mode-low-dark {
    background: #cfccff;
    color: $black;
}
.time-mode-medium-dark {
    background: $medium;
    color: $white;
}
.time-mode-high-dark {
    background: $high-occupancy;
    color: $white;
}
.time-mode-standby-white {
    background: $occupied-light;
    color: $black;
}
.time-mode-low-white {
    background: #cfccff;
    color: $black;
}
.time-mode-medium-white {
    background: $medium;
    color: $white;
}
.time-mode-high-white {
    background: $high-occupancy;
    color: $white;
}
.schedule-time-pin {
    @extend .current-time-pin;
}
.schedule-time-indicator {
    width: 0.063rem;
    height: 2rem;
    background-color: $white;
    position: relative;
}
.air-cleaning-tab-section {
    height: 1rem;
    width: auto;
    border-radius: 0.25rem;
    padding: 0 0.313rem;
}
.manual-air-cleaning-tab-section {
    height: 1rem;
    border-radius: 0.25rem;
    @extend .LabelBase;
}
.manual-air-cleaning-tab-section-non-occupied {
    @extend .manual-air-cleaning-tab-section;
    width: 5rem;
}
.schedule_commands_buttons {
    border-radius: 1rem;
    background: $gray !important;
}
.manual-air-cleaning-timepanel-tab-section {
    height: 1.313rem;
    border-radius: 0.25rem;
    margin-left: 1.75rem;
}
.planned-air-cleaning-state-margin {
    margin-top: 0.688rem;
}
.schedular-container-fixed_height {
    height: 2.063rem;
}
.copy_schedule_form {
    width: 18.75rem;
    height: 26.063rem;
    margin-top: 50%;
}
.horizontal_border {
    width: 18.625rem !important;
    height: 1px !important;
    margin-left: -15px !important;
    margin-top: 17px !important;
}
.copy-icon {
    margin-left: 0.563rem;
    margin-right: 1.188rem;
}
.copy-icon-empty-space {
    margin-left: 1.75rem;
    margin-right: 1.188rem;
}

.triangle-down {
    width: 1rem;
    height: 1rem;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 0.813rem solid $time-indicator;
    transform: rotate(180deg);
    position: absolute;
    bottom: 1.875rem;
    z-index: 99;
    margin: -0.5rem;
}
.triangle-down-icon {
    width: 0.75rem;
    height: 0.75rem;
    border-left: 0.375rem solid transparent;
    border-right: 0.375rem solid transparent;
    border-bottom: 0.625rem solid $time-indicator;
    transform: rotate(180deg);
}
.toggle-container {
    width: 5.688rem;
    height: 2rem;
    padding: 0.188rem 0.313rem 0.188rem 0.313rem;
}

.toggle-button {
    flex: 1;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.toggle-button.selected {
    background-color: $primary-button;
}
.toggle-button:focus,
.toggle-button:hover,
.toggle-button:active {
    outline: none !important;
    box-shadow: none !important;
}
