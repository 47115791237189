.pagination-container {
    display: flex;
    list-style-type: none;

    .pagination-item {
        padding: 0 12px;
        min-width: 32px;
        height: 32px;
        text-align: center;
        margin: auto 4px;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        letter-spacing: 0.01071em;
        @include border-radius(8px);

        &.dots:hover {
            background-color: transparent;
            cursor: default;
        }
        &:hover {
            cursor: pointer;
        }

        &.selected {
            font-weight: bold;
        }
    }
}
