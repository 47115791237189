.preset-buttons {
    border: none !important;
    background: none !important;
    cursor: pointer;
    font-size: $font-12 !important;
    font-weight: 400 !important;
}

.calendar-container {
    min-width: 18.75rem;

    .rdrDateRangeWrapper {
        .rdrDay:not(.rdrDayPassive) .rdrInRange,
        .rdrDay:not(.rdrDayPassive) .rdrStartEdge,
        .rdrDay:not(.rdrDayPassive) .rdrEndEdge {
            background-color: $br-01 !important;
        }

        .rdrDay:not(.rdrDayPassive) .rdrStartEdge,
        .rdrDay:not(.rdrDayPassive) .rdrEndEdge {
            background-color: $primary !important;
        }
    }
}

.preset-buttons.active {
    background: $primary !important;
    color: $white !important;
}

.range-selector-vertical-line {
    @extend .site-rhs-vertical-line;
    height: 21.875rem;
    width: 0.063rem;
}

.light-range-picker {
    .rdrCalendarWrapper {
        background-color: $white !important;
        color: $t-01-light !important;

        .rdrDay {
            span.rdrDayNumber {
                font-size: 0.875rem !important;
            }

            &.rdrDayStartPreview,
            &.rdrDayEndPreview,
            &.rdrDayInPreview {
                span.rdrDayNumber {
                    color: $black !important;
                }
            }

            .rdrInRange ~ .rdrDayNumber span,
            .rdrStartEdge ~ .rdrDayNumber span,
            .rdrEndEdge ~ .rdrDayNumber span {
                color: $black !important;
            }

            .rdrStartEdge,
            .rdrEndEdge {
                & ~ span.rdrDayNumber {
                    font-weight: 700 !important;
                }
            }
        }
    }

    .rdrDayDisabled,
    .rdrDayDisabled * {
        background-color: $white !important;
    }
}

.dark-range-picker {
    .rdrCalendarWrapper {
        background-color: $background-03 !important;
        color: $t-01 !important;

        .rdrDay {
            span.rdrDayNumber {
                font-size: 0.875rem !important;
            }

            .rdrInRange ~ .rdrDayNumber span,
            .rdrStartEdge ~ .rdrDayNumber span,
            .rdrEndEdge ~ .rdrDayNumber span {
                color: $white !important;
            }

            .rdrStartEdge ~ .rdrDayNumber span,
            .rdrEndEdge ~ .rdrDayNumber span {
                font-weight: 700 !important;
            }
        }
    }
    .rdrDays,
    .rdrDays * {
        color: $white;
    }
    .rdrDayDisabled,
    .rdrDayDisabled * {
        background-color: $background-03 !important;
    }
    .rdrWeekDays,
    .rdrWeekDays * {
        color: $white;
    }
}

.custom-range-dropdown-menu {
    left: -17.5rem !important;
}

.active-preset {
    background-color: $primary !important;
    color: $white !important;
}
.device-time-series-dropdown-graph-date-range {
    right: 0 !important;
    left: auto !important;
    position: fixed !important;
}
