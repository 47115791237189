@font-face {
    font-family: 'icomoon';
    src: url('../assets/icons/icomoon.eot?s9s6pw');
    src: url('../assets/icons/icomoon.eot?s9s6pw#iefix') format('embedded-opentype'),
        url('../assets/icons/icomoon.ttf?s9s6pw') format('truetype'),
        url('../assets/icons/icomoon.woff?s9s6pw') format('woff'),
        url('../assets/icons/icomoon.svg?s9s6pw#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon', sans-serif !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down-s-line-1:before {
    content: "\e902";
  }
  
.icon-download_icon:before {
    content: '\e901';
}
.icon-device:before {
    content: '\e900';
}
.icon-healthy_env:before {
    content: '\ead4';
}
.icon-increased_productivity:before {
    content: '\ead5';
}
.icon-temperature_high:before {
    content: '\ead8';
}
.icon-temperature_moderate:before {
    content: '\ead9';
}
.icon-no_data_available:before {
    content: '\ead6';
    color: #10475b;
}
.icon-renewal_failure:before {
    content: '\ead7';
}
