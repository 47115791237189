.custom-dashboard {
    overflow: hidden;

    &.chart-text-center {
        position: absolute;
        text-align: center;
        z-index: 999;
        top: 0;
        right: 30px;
        bottom: 42px;
        left: 0;
        border-radius: 50%;
        margin: 70px;
    }

    &.dashboard-img {
        position: absolute;
        left: 100px;
        top: 0;
        height: 100%;
        width: 100%;
    }
}

.custom-alert {
    &.alert-icon {
        @include border-radius(2px);
        border-width: 2px !important;
        width: 45px;
        height: 45px;
    }
}
