.custom-checkbox {
    &.radio {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;
        .selected {
            height: 8px;
            width: 8px;
            border-radius: 50%;
            display: inline-block;
        }
    }
    &.checkbox {
        height: 14px;
        width: 14px;
        border-radius: 2px;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;
        .icon {
            font-size: 10px;
        }
    }
}
