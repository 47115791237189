.custom-modal {
    z-index: 9999999;

    .modal-content {
        @include border-radius(4px);
        border: 1px solid $primary-100;
        box-sizing: border-box;
    }
    .modal-header {
        @extend .font-large-bold;
        padding: 20px 30px 15px 30px;
        .close {
            text-shadow: none;
            padding: 10px 20px 10px 30px !important;
            opacity: 1;
        }
    }
    .modal-body {
        @extend .mb-2;
    }
}
