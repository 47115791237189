/******* Theme Colors *******/
//override coreUi Theme Colors

//Backgrounds
//Dark
$background-01: #081219;
$background-02: #19354c;
$background-03: #112333;
//Light
$body-bg-light: #eef7ff;
$background-01-light: #eef7ff;
$background-02-light: #e6e5e64d;
$background-02-light-0-opacity: #e6e5e6;
$background-03-light: #ffffff;

// Text
//Dark
$t-01: #ffffff;
$t-02: #112333;
$t-03: #5df484;
//Light
$t-01-light: #112333;
$t-02-light: #ffffff;
$t-03-light: #36cb79;

// Borders
// Dark
$br-01: #448eca66;
$br-02: #2b597f;
$br-03: #aad8fe;
// Light
$br-01-light: #e6e5e6;
$br-02-light: #817b8080;
$br-03-light: #448eca;

// Buttons
$primary-button: #448eca;

//override coreUi status Colors
$white: #ffffff;
$white-transparent: rgba(255, 255, 255, 0);
$black: #000015;

//Placeholder
$placeholder-color: rgb(255, 255, 255, 0.6);
$placeholder-color-light: rgb(17, 35, 51, 0.6);

$success: #36cb79;
$toaster-success: #d2ffe6;
$toaster-danger: #ffdce5;
$toaster-warning: #fff0cf;
$toaster-info: #d4e9ff;
$info: #3399ff;
$standby: #55b1fd;
$medium: #726adf;
$warning: #f9b115;
$warning-light: #f9b11533;

$danger-light: #2d0005;
$danger: #f41148;

$yellow: #ebcf68;
$black-200: #082e3a;
$black-400: #101c20;
$black-600-opacity: #00000099;
$black-700: #151825;
$white: #fff;
$white-600-opacity: #ffffff99;
$white-800-opacity: #ffffffcc;
$primary-100-opacity: #29b3e34d;
$primary-200-opacity: #2bb5e333;
$primary-400-opacity: #29b3e366;
$disabled: #414141;

$hazardous: #f41148;
$hazardous-400-opacity: rgb(244, 17, 72, 0.4);
$hazardous-100-opcaity: #ee293d4d;
$hazardous-light: #ee2b3e33;

$moderate: #874cee;
$moderate-400-opacity: #874cee66;
$healthy: #08b0e7;
$healthy-400-opacity: #08b0e766;

$primary: #28b2e3;
$primary-light: #d3edff;
$primary-opacity-18: #26b1e32e;
$primary-25: #d4f0f9;
$primary-50: #bee8f7;
$primary-100: #24a0cc;
$primary-200: #208eb6;
$primary-300: #1c7d9f;
$primary-300-opacity-3: #1c7da04d;
$primary-400: #186b88;
$primary-500: #145971;
$primary-500-opacity-25: rgb(20, 89, 113, 0.25);
$primary-600: #10475b;
$primary-600-opacity-6: #10475b99;
$primary-700: #0c3544;
$primary-800: #08242d;

$secondary: #011a6c;
$secondary-50: #858991;
$secondary-300: #011041;
$secondary-400: #000d36;
$secondary-600-opcaity-6: #000a2999;
$secondary-dark-1: #0c1322;
$secondary-dark-2: #0e172b;

$tertiary: #874cee;
$tertiary-light: #10002d33;

$dataerror: #817b80;

$gray: #817b80;
$gray-30: #9a9599;
$gray-20-opacity: #817b8033;
$gray-900: #999699;

$ideal: #36cb79;
$ideal-400-opacity: #36cb7966;
$ideal-light: #36cb7933;

$bottom-bar: #336a98;

$table-border-color: $white-transparent;

// Core ui dropdown default colors overwrite
$dropdown-link-hover-bg: $primary;

$nav-tabs-link-active-bg: $secondary-dark-2;

$nav-tabs-link-hover-border-color: $primary;
$popover-border-color: $br-01;
$popover-bg: $primary-button;
$time-indicator: #ff7914;
$high-occupancy: #352f7d;
$occupied-light: #efeefa;

$theme-colors: (
    'primary': $primary-button,
    'primary-button': $primary-button,
    'primary-100': $primary-100,
    'primary-200': $primary-200,
    'primary-300': $primary-300,
    'primary-400': $primary-400,
    'primary-500': $primary-500,
    'primary-600': $primary-600,
    'primary-700': $primary-700,
    'primary-800': $primary-800,
    'primary-400-opacity': $primary-400-opacity,
    'primary-600-opacity-6': $primary-600-opacity-6,
    'primary-300-opacity-3': $primary-300-opacity-3,
    'primary-100-opacity': $primary-100-opacity,
    'primary-light': $primary-light,
    'secondary': $secondary,
    'secondary-50': $secondary-50,
    'secondary-400': $secondary-400,
    'secondary-dark-1': $secondary-dark-1,
    'secondary-dark-2': $secondary-dark-2,
    'secondary-600-opcaity-6': $secondary-600-opcaity-6,
    'tertiary': $tertiary,
    'tertiary-light': $tertiary-light,
    'success': $success,
    'toaster-success': $toaster-success,
    'toaster-danger': $toaster-danger,
    'toaster-warning': $toaster-warning,
    'toaster-info': $toaster-info,
    'warning': $warning,
    'info': $info,
    'danger': $danger,
    'danger-light': $danger-light,
    'gray': $gray,
    'gray-30': $gray-30,
    'gray-900': $gray-900,
    'gray-20-opacity': $gray-20-opacity,
    'yellow': $yellow,
    'dark': $black,
    'disabled': $gray,
    'ideal': $ideal,
    'healthy': $healthy,
    'moderate': $moderate,
    'hazardous': $hazardous,
    'hazardous-light': $hazardous-light,
    'offline': $gray,
    'black-700': $black-700,
    'black-600-opacity': $black-600-opacity,
    'background-01': $background-01,
    'background-02': $background-02,
    'background-03': $background-03,
    'borderbackground-01-light': $background-01-light,
    'background-02-light': $background-02-light,
    'background-03-light': $background-03-light,
    'background-02-light-0-opacity': $background-02-light-0-opacity,
    'br-01': $br-01,
    'br-02': $br-02,
    'br-03': $br-03,
    'br-01-light': $br-01-light,
    'br-02-light': $br-02-light,
    'br-03-light': $br-03-light,
    't-01': $t-01,
    't-02': $t-02,
    't-03': $t-03,
    't-01-light': $t-01-light,
    't-02-light': $t-02-light,
    't-03-light': $t-03-light,
    'ideal-400-opacity': $ideal-400-opacity,
    'healthy-400-opacity': $healthy-400-opacity,
    'moderate-400-opacity': $moderate-400-opacity,
    'hazardous-400-opacity': $hazardous-400-opacity,
    'primary-500-opacity-25': $primary-500-opacity-25,
    'dataerror': $dataerror,
    'DataError': $dataerror,
);
