.custom-sidebar {
    &.sidebar {
        @extend .font-large;
        overflow: hidden;

        .c-active {
            @include border-radius(2px);
            @extend .shadow-1, .font-large-bold;
        }
        .c-sidebar-nav-link {
            margin-top: 5px;
            margin-bottom: 5px;
            justify-content: start;
            align-items: center;
        }
        .sidebar-img {
            opacity: 0.09;
            position: absolute;
            left: -35px;
            top: 0;
            width: 130%;
            height: 100%;
            z-index: -1;
        }
        @media (min-width: 1000px) {
            .sidebar-img {
                width: 100%;
                left: 0;
            }
        }
        @media (min-width: 1400px) {
            .sidebar-img {
                width: 140%;
                left: -60px;
            }
        }
        @media (min-width: 2000px) {
            .sidebar-img {
                width: 180%;
                left: -100px;
            }
        }
    }
    &.rhs-sidebar {
        top: $sidebar-top;
        max-height: calc(100% - #{$sidebar-top});
        .dropdown-text {
            white-space: normal;
            overflow: hidden;
        }
        .sidebar-connected-icon {
            position: absolute;
            top: 0px;
        }
    }
}

.btn-sidebar-toggle {
    position: fixed;
    right: 0;
    top: $sidebar-top + 2;
    z-index: 1021 !important;
    @include border-radius(2px);
    transition: right 0.3s ease-out;

    .panel_open_rotated {
        transform: rotate(180deg);
    }

    .panel_open_icon {
        transform: rotate(180deg);
    }
    &.panel_open {
        margin-right: 380px;
        &.device-rhs {
            margin-right: 46rem;
        }
        &.site-edit-rhs {
            margin-right: 39rem;
        }
    }
}
