.custom-table-firmwares {
    &.fix-height {
        height: 82vh;
        overflow: scroll;
    }
}

.fileBox {
    width: 15.625rem;
    height: 3.688rem;
}

.overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.spinner-large {
    height: 3.125rem;
    width: 3.125rem;
}

.dropzone {
    border: 0.063rem dashed var(--border-br-02-night, $bottom-bar);
    height: 2.688rem;
}

.ql-toolbar.ql-snow {
    @extend .no-border;
    display: flex;
}

.ql-container.ql-snow {
    @extend .no-border;
}

.ql-picker-label {
    svg {
        width: 0px !important;
    }
}

.ql-container.ql-snow .ql-editor {
    font-weight: $font-weight-light;
    font-size: $font-14;
    font-family: $font-base-family;
}

.ql-container.ql-snow .ql-editor::before {
    font-weight: $font-weight-light;
    font-size: $font-14;
    font-family: $font-base-family;
}

.ql-snow.ql-toolbar button.ql-active .ql-stroke {
    stroke: $primary-button !important;
}

.ql-snow.ql-toolbar button.ql-active .ql-fill {
    stroke: $primary-button !important;
}

.ql-picker-label:after {
    content: '\e902';
    font-family: 'icomoon', sans-serif;
    position: absolute;
    right: 0.625rem;
}

.ql-snow.ql-toolbar .ql-picker-label {
    color: $primary-button !important;
}
.ql-editor.ql-blank::before {
    font-style: normal !important;
    font-size: $font-12 !important;
}
.ql-toolbar.ql-snow .ql-formats {
    margin-right: 0.625rem !important;
}
.dark-theme-editor {
    .ql-picker-label:after {
        color: $white !important;
    }

    .ql-toolbar.ql-snow {
        background: $background-02 !important;
    }

    .ql-snow .ql-stroke {
        stroke: $white !important;
    }

    .ql-snow .ql-fill {
        stroke: $white !important;
    }

    .ql-picker-label {
        color: $white;
    }

    .ql-picker-options {
        background: $background-02 !important;
        color: $white !important;
    }
    .ql-container.ql-snow .ql-editor::before {
        color: $placeholder-color;
    }
}

.light-theme-editor {
    .ql-picker-label:after {
        color: $black !important;
    }

    .ql-toolbar.ql-snow {
        background: $background-02-light !important;
    }

    .ql-snow .ql-stroke {
        stroke: $black !important;
    }

    .ql-snow .ql-fill {
        stroke: $black !important;
    }

    .ql-snow.ql-toolbar .ql-picker-label {
        color: $black !important;
    }

    .ql-picker-label {
        color: $black;
    }

    .ql-picker-options {
        background: $background-02-light-0-opacity !important;
        color: $black !important;
    }
    .ql-container.ql-snow .ql-editor::before {
        color: $placeholder-color-light;
    }
}

.firmware-command-box-size {
    min-width: 10.5rem;
}
