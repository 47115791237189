.custom-chart {
    &.custom-node {
        width: 430px;
        .node {
            @include border-radius(4px);
            .chart {
                @include border-radius(50%);
            }
        }
        .node-img {
            position: absolute;
            top: -2px;
        }
    }
}

.react-flow__node {
    .react-flow__handle {
        border: 0px !important;
        z-index: -99;
    }
}
