.c-switch-sm {
    width: 3rem;
    height: 1.438rem;
}
.c-switch-sm .c-switch-input:checked ~ .c-switch-slider::before {
    transform: translate(24px);
}
.c-switch-slider::before {
    background-color: #448eca;
    top: 0.188rem;
    border: none;
}
.c-switch-primary .c-switch-input:checked + .c-switch-slider::before {
    border: none;
}
.c-switch-pill .c-switch-slider::before {
    margin-left: 0.188rem;
}

@mixin switch-theme($backgroundColor) {
    .c-switch-primary .c-switch-input:checked + .c-switch-slider {
        background-color: $backgroundColor;
        border: none;
    }
    .c-switch-pill .c-switch-slider {
        background-color: $backgroundColor;
        border: none;
    }
    .c-switch-input:focus ~ .c-switch-slider {
        background-color: $backgroundColor;
        border: none;
        box-shadow: none;
    }
}
.switch-wrapper {
    position: relative;
}
.switch-dark {
    @include switch-theme($br-01);
}
.switch-light {
    @include switch-theme($br-01-light);
}

.switch-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.75rem;
    pointer-events: none;
}

.switch-text-yes {
    left: 30%;
    top: 0.75rem;
    opacity: 60%;
}

.switch-text-no {
    right: 50%;
    top: 0.75rem;
    opacity: 60%;
}
