.custom-card {
    border: none;
    &.device-card {
        @include border-radius(8px);
        .card-score {
            @include border-radius(8px);
            border-width: 2px !important;
        }
        .border-moderate {
            border: 2px solid;
        }
    }
    &.card {
        border: 2px solid;
        .header {
            @extend .font-body-bold;
            padding: 1.5rem 1.8rem 1.5rem 1.8rem;
            &.achievement {
                height: 130px;
                background-image: url('../../assets/images/achivement_background.png');
                background-size: cover;
            }
        }
        .card-body {
            padding: 1.5rem 1.8rem 1.5rem 1.8rem;
        }
        .carousel-indicators li {
            width: 5px;
            height: 5px;
            border-radius: 100%;
        }
        .footer {
            border-top: 1px solid #29b3e34d;
        }
        &.alert-card {
            height: 430px;
        }
        &.rhs-card {
            height: 350px;
            .header_icon {
                top: 30px;
                font-size: 50px;
                right: 0;
            }
        }
        .chart {
            @include border-radius(50%);
        }
        .carousel {
            height: 170px;
        }
    }
    &.performace-card {
        min-height: 162px;
        position: relative;
    }
    &.arrow-left-dark::before {
        @extend .arrow-left;
        background: $background-02;
        border-right: 2px solid $primary-button;
        border-top: 2px solid $primary-button;
    }
    &.arrow-right-dark::before {
        @extend .arrow-right;
        background: $background-02;
        border-left: 2px solid $primary-button;
        border-bottom: 2px solid $primary-button;
    }
    &.arrow-left-light::before {
        @extend .arrow-left;
        background: #f7f7f7;
        border-right: 2px solid $primary-button;
        border-top: 2px solid $primary-button;
    }
    &.arrow-right-light::before {
        @extend .arrow-right;
        background: #f7f7f7;
        border-left: 2px solid $primary-button;
        border-bottom: 2px solid $primary-button;
    }
    &.performance-parent-card {
        min-height: 380px;
    }
    &.org-card {
        background: none;
        border: none;
        .card-header {
            background: none;
            border-bottom: none;
        }
    }
    &.doughnut-card {
        background: none;
        border: none;
        .card-body {
            padding: 0rem 1.8rem 0rem 0rem;
            width: 430px;
        }
    }
}

.arrow {
    width: 15px;
    height: 15px;
    top: 45%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    content: ' ';
}

.arrow-left {
    @extend .arrow; 
    right: -8.5px;
    @media screen and (max-width: 800px)  {
        right: -8px;
      }
}
.arrow-right {
    @extend .arrow;
    left: -8.5px;
    @media screen and (max-width: 800px) {
        left: -8px;
      }
}
