.custom-dropdown {
    @extend .font-body;
    &.form-control {
        border-radius: 2px !important;
    }
    &.invalid-input {
        border: 1px solid $danger;
    }
    .dropdown-text {
        word-break: break-word;
        white-space: normal;
    }
    &.invalid {
        color: $danger;
        width: 100%;
    }
    .dropdown-menu {
        transform: translate(0px, 34px) !important;
    }
    &.height {
        height: 42px;
    }
    &.small-height {
        height: 32px;
    }
}

.btndisablecolor {
    background-color: $background-03 !important;
    border: 1px solid rgba(68, 142, 202, 0.4);
}
.btnenablecolor {
    background-color: $white !important;
    border: 1px solid $background-02-light-0-opacity;
}
.customDropdownWithIcon {
    border: 1px solid rgba(68, 142, 202, 0.4);
    width: 232px;
    margin-top: 8px !important;
    text-transform: capitalize !important;
}
.dropdown-toggle::after {
    display: none !important;
}

.dropdwonmenu-font {
    font-weight: 400 !important;
}
.dropdownSpace {
    white-space: pre !important;
    word-break: break-all !important;
}
.btndisablecolor-customrounddropdwon {
    background: $background-02 !important;
    border: 1px solid $primary-500 !important;
    @include border-radius(16px);
    @include no-box-shadow;
    &:hover {
        @include no-box-shadow;
        background: $background-01 !important;
        border: 1px solid $primary-500;
    }
    opacity: 70%;
}
.btnenablecolor-customrounddropdwon {
    background: $background-02-light !important;
    border: 1px solid $br-01-light !important ;
    @include border-radius(16px);
    @include no-box-shadow;
    &:hover {
        @include no-box-shadow;
        background: $background-01-light !important;
        border: 1px solid $br-01-light;
    }
    opacity: 70%;
}
.btndisablecolor-customrounddropdwon-clicked {
    background: $background-01 !important;
    border: 2px solid var(--button-bt-01-day, $br-03-light) !important;
    @include border-radius(16px);
    @include no-box-shadow;
}
.btnenablecolor-customrounddropdwon-clicked {
    background: $background-02-light !important;
    border: 2px solid var(--button-bt-01-day, $br-03-light) !important;
    @include border-radius(16px);
    @include no-box-shadow;
}
.install-allocate-dropdown-item-section {
    background: $background-01;
}
.dropdown-value-selected-dark {
    color: #fff9 !important;
}
.dropdown-value-selected-light {
    color: #11233399 !important;
}
